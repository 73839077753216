.options-criar-regra {
    background: white;
    border-radius: 7px;
    padding: 15px;
    border: 1px solid gray;
    font-size: 13px;
}

.options-criar-regra h5 {
    color: black;
}

.btn_save {
    background: darkgreen;
    font-weight: 600;
    color: white;
    border: none;
    border-radius: 7px;
    padding: 10px 15px;    
}

.options-criar-regra label {
    margin: 0;
    font-size: 14px;    
}

.adicionar_regra input {
    border: 1px solid darkgreen;
    padding: 10px 15px;
    border-radius: 7px;
}

.input-moeda {
    font-size: 14px;
    width: 80px;
    border-radius: 4px;
    outline: 0;
    box-shadow: none;
    border: 1px solid black;
    color: black;
    padding-left: 25px !important;
}

.input-moeda + *::before{
    content: "R$";
    display: block;
    position: absolute;
    top: 0;
    left: 20px;
    color: black;
}

